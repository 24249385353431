<template>
    <div class="material-documents__item">
        <div class="material-documents__row material-documents__row--top">
            <div class="material-documents__col">
                <span class="material-documents__name">
                    {{ documentData.name }}
                </span>
            </div>

            <div
                v-if="documentData.link"
                class="material-documents__col"
            >
                <div class="material-documents__actions">
                    <button
                        v-if="!isExternalLink(documentData.link)"
                        type="button"
                        aria-label="Открыть документ"
                        class="material-documents__action"
                        v-tooltip="`Открыть`"
                        @click="onPreview(documentData)"
                    >
                        <icon width="18" height="14" icon-color="#989CAE">
                            <path d="M2.33268 0.333008C1.41221 0.333008 0.666016 1.0792 0.666016 1.99967V11.9997C0.666016 12.9202 1.41221 13.6663 2.33268 13.6663H7.33268C7.97302 13.6663 8.55702 13.4256 8.99935 13.0298C9.44168 13.4256 10.0257 13.6663 10.666 13.6663H15.666C16.5865 13.6663 17.3327 12.9202 17.3327 11.9997V1.99967C17.3327 1.0792 16.5865 0.333008 15.666 0.333008H10.666C10.0257 0.333008 9.44168 0.573716 8.99935 0.969591C8.55702 0.573716 7.97302 0.333008 7.33268 0.333008H2.33268ZM8.16602 2.83301V11.1663C8.16602 11.6266 7.79293 11.9997 7.33268 11.9997H2.33268V1.99967H7.33268C7.79293 1.99967 8.16602 2.37277 8.16602 2.83301ZM9.83268 11.1663V2.83301C9.83268 2.37277 10.2058 1.99967 10.666 1.99967H15.666V11.9997H10.666C10.2058 11.9997 9.83268 11.6266 9.83268 11.1663Z"/>
                        </icon>
                    </button>

                    <a
                        v-else
                        :href="documentData.link" target="_blank"
                        type="button"
                        aria-label="Открыть документ"
                        class="material-documents__action"
                        v-tooltip="`Открыть`"
                    >
                        <icon width="18" height="14" icon-color="#989CAE">
                            <path d="M2.33268 0.333008C1.41221 0.333008 0.666016 1.0792 0.666016 1.99967V11.9997C0.666016 12.9202 1.41221 13.6663 2.33268 13.6663H7.33268C7.97302 13.6663 8.55702 13.4256 8.99935 13.0298C9.44168 13.4256 10.0257 13.6663 10.666 13.6663H15.666C16.5865 13.6663 17.3327 12.9202 17.3327 11.9997V1.99967C17.3327 1.0792 16.5865 0.333008 15.666 0.333008H10.666C10.0257 0.333008 9.44168 0.573716 8.99935 0.969591C8.55702 0.573716 7.97302 0.333008 7.33268 0.333008H2.33268ZM8.16602 2.83301V11.1663C8.16602 11.6266 7.79293 11.9997 7.33268 11.9997H2.33268V1.99967H7.33268C7.79293 1.99967 8.16602 2.37277 8.16602 2.83301ZM9.83268 11.1663V2.83301C9.83268 2.37277 10.2058 1.99967 10.666 1.99967H15.666V11.9997H10.666C10.2058 11.9997 9.83268 11.6266 9.83268 11.1663Z"/>
                        </icon>
                    </a>

                    <button
                        v-if="!isExternalLink(documentData.link)"
                        type="button"
                        aria-label="Скачать документ"
                        class="material-documents__action"
                        v-tooltip="`Скачать`"
                        @click="onDownload(documentData.link)"
                    >
                        <icon width="14" height="17" icon-color="#989CAE">
                            <path d="M7.5 0.833333C7.5 0.3731 7.12692 0 6.66667 0C6.20642 0 5.83333 0.3731 5.83333 0.833333V10.9048L3.08926 8.16075C2.76382 7.83533 2.23618 7.83533 1.91074 8.16075C1.58531 8.48617 1.58531 9.01383 1.91074 9.33925L6.07742 13.5059C6.40283 13.8313 6.9305 13.8313 7.25592 13.5059L11.4226 9.33925C11.748 9.01383 11.748 8.48617 11.4226 8.16075C11.0972 7.83533 10.5695 7.83533 10.2441 8.16075L7.5 10.9048V0.833333ZM0.833333 15C0.3731 15 0 15.3731 0 15.8333C0 16.2936 0.3731 16.6667 0.833333 16.6667H12.5C12.9602 16.6667 13.3333 16.2936 13.3333 15.8333C13.3333 15.3731 12.9602 15 12.5 15H0.833333Z"/>
                        </icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="material-documents__row material-documents__row--bottom">
            <div class="material-documents__col">
                <div class="material-documents__row material-documents__row--table">
                    <div class="material-documents__col">
                        <span class="material-documents__label">Номер документа</span>
                        <span class="material-documents__value">
                            {{ documentData.number && documentData.number !== 'null' ? documentData.number : '—' }}
                        </span>
                    </div>

                    <div class="material-documents__col">
                        <span class="material-documents__label">Статус ознакомления</span>
                        <div
                            v-if="documentData.study_status && documentData.study_status !== 3 && !isEditor"
                            :class="['material-documents-status', { 'material-documents-status--show': isStatusShow }]"
                            tabindex="0"
                            @click="onChangeStatus"
                            @blur="isStatusShow = false"
                        >
                            <div class="material-documents-status__header">
                                    <span class="material-documents-status__value">
                                        <icon
                                            v-if="statusReviewValue['key'] === 1"
                                            width="17"
                                            height="15"
                                            icon-color="#eaa41e"
                                        >
                                            <path d="M8.04003 10.33C7.59046 10.33 7.21362 10.7068 7.21362 11.1564C7.21362 11.606 7.59046 11.9828 8.04003 11.9828C8.47306 11.9828 8.86643 11.606 8.84659 11.1762C8.86643 10.7035 8.49289 10.33 8.04003 10.33Z"/>
                                            <path d="M15.6892 13.2588C16.2082 12.363 16.2115 11.2952 15.6958 10.4027L10.5193 1.43794C10.0069 0.535508 9.08133 0 8.04337 0C7.00541 0 6.07984 0.538813 5.56748 1.43463L0.384292 10.4093C-0.131382 11.3118 -0.128076 12.3861 0.394209 13.2819C0.909883 14.1678 1.83215 14.7 2.86349 14.7H13.2034C14.2381 14.7 15.1669 14.1612 15.6892 13.2588ZM14.5653 12.6109C14.2777 13.1067 13.7687 13.4009 13.2001 13.4009H2.86019C2.29824 13.4009 1.79248 13.1133 1.5115 12.6274C1.22722 12.1349 1.22391 11.5465 1.5082 11.0506L6.69138 2.07922C6.97236 1.58669 7.47481 1.2958 8.04337 1.2958C8.60863 1.2958 9.11439 1.58999 9.39536 2.08253L14.5752 11.0539C14.8529 11.5366 14.8496 12.1183 14.5653 12.6109Z"/>
                                            <path d="M7.83518 4.52868C7.44182 4.64107 7.1972 4.99807 7.1972 5.43111C7.21703 5.69225 7.23356 5.9567 7.2534 6.21784C7.30959 7.21283 7.36579 8.18798 7.42198 9.18297C7.44182 9.52014 7.70296 9.76475 8.04013 9.76475C8.3773 9.76475 8.64175 9.50361 8.65828 9.16313C8.65828 8.95818 8.65828 8.76976 8.67811 8.56151C8.71447 7.92353 8.75414 7.28555 8.7905 6.64757C8.81033 6.23437 8.8467 5.82117 8.86653 5.40797C8.86653 5.25921 8.8467 5.12699 8.7905 4.99477C8.62191 4.62454 8.22855 4.43612 7.83518 4.52868Z"/>
                                        </icon>

                                        <icon
                                            v-if="statusReviewValue['key'] === 2"
                                            width="15"
                                            height="12"
                                            icon-color="#57BD4E"
                                        >
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5682 1.70325L4.95611 11.4222L0.289062 6.70325L1.71107 5.29688L4.95611 8.57797L13.1462 0.296875L14.5682 1.70325Z"/>
                                        </icon>

                                        {{ statusReviewValue['value'] }}
                                    </span>

                                <icon class="material-documents-status__arrow" width="12" height="8" icon-color="#B5B5B5">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00008 4.58586L1.70718 0.292969L0.292969 1.70718L6.00008 7.41429L11.7072 1.70718L10.293 0.292969L6.00008 4.58586Z"/>
                                </icon>
                            </div>

                            <div
                                v-for="statusItem in statusReviewList"
                                :key="`document-status-${statusItem.key}`"
                                class="material-documents-status__dropdown"
                            >
                                <div
                                    class="material-documents-status__option"
                                    :data-status-id="statusItem['key']"
                                >
                                    <icon
                                        v-if="statusItem['key'] === 1"
                                        width="17"
                                        height="15"
                                        icon-color="#eaa41e"
                                    >
                                        <path d="M8.04003 10.33C7.59046 10.33 7.21362 10.7068 7.21362 11.1564C7.21362 11.606 7.59046 11.9828 8.04003 11.9828C8.47306 11.9828 8.86643 11.606 8.84659 11.1762C8.86643 10.7035 8.49289 10.33 8.04003 10.33Z"/>
                                        <path d="M15.6892 13.2588C16.2082 12.363 16.2115 11.2952 15.6958 10.4027L10.5193 1.43794C10.0069 0.535508 9.08133 0 8.04337 0C7.00541 0 6.07984 0.538813 5.56748 1.43463L0.384292 10.4093C-0.131382 11.3118 -0.128076 12.3861 0.394209 13.2819C0.909883 14.1678 1.83215 14.7 2.86349 14.7H13.2034C14.2381 14.7 15.1669 14.1612 15.6892 13.2588ZM14.5653 12.6109C14.2777 13.1067 13.7687 13.4009 13.2001 13.4009H2.86019C2.29824 13.4009 1.79248 13.1133 1.5115 12.6274C1.22722 12.1349 1.22391 11.5465 1.5082 11.0506L6.69138 2.07922C6.97236 1.58669 7.47481 1.2958 8.04337 1.2958C8.60863 1.2958 9.11439 1.58999 9.39536 2.08253L14.5752 11.0539C14.8529 11.5366 14.8496 12.1183 14.5653 12.6109Z"/>
                                        <path d="M7.83518 4.52868C7.44182 4.64107 7.1972 4.99807 7.1972 5.43111C7.21703 5.69225 7.23356 5.9567 7.2534 6.21784C7.30959 7.21283 7.36579 8.18798 7.42198 9.18297C7.44182 9.52014 7.70296 9.76475 8.04013 9.76475C8.3773 9.76475 8.64175 9.50361 8.65828 9.16313C8.65828 8.95818 8.65828 8.76976 8.67811 8.56151C8.71447 7.92353 8.75414 7.28555 8.7905 6.64757C8.81033 6.23437 8.8467 5.82117 8.86653 5.40797C8.86653 5.25921 8.8467 5.12699 8.7905 4.99477C8.62191 4.62454 8.22855 4.43612 7.83518 4.52868Z"/>
                                    </icon>

                                    <icon
                                        v-if="statusItem['key'] === 2"
                                        width="15"
                                        height="12"
                                        icon-color="#57bd4e"
                                    >
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5682 1.70325L4.95611 11.4222L0.289062 6.70325L1.71107 5.29688L4.95611 8.57797L13.1462 0.296875L14.5682 1.70325Z"/>
                                    </icon>

                                    {{ statusItem['value'] }}
                                </div>
                            </div>
                        </div>
                        <span
                            v-else
                            class="material-documents__value"
                        >—</span>
                    </div>

                    <div class="material-documents__col">
                        <span class="material-documents__label">Дата ознакомления</span>
                        <span class="material-documents__value">
                            {{
                                !isEditor ?
                                    documentData.date_study && documentData.date_study !== 'null' ? $moment(documentData.date_study).format('DD MMMM YYYY') : '—'
                                    : '—'
                            }}
                        </span>
                    </div>

                    <div class="material-documents__col">
                        <span class="material-documents__label">Статус тестирования</span>
                        <span class="material-documents__value">
                            {{
                                !isEditor && documentData.study_status !== null ?
                                    statusTestingValue :
                                    '—'
                            }}
                        </span>
                    </div>

                    <div
                        v-if="status.key === 3"
                        class="material-documents__col"
                    >
                        <span class="material-documents__label">Дата тестирования</span>
                        <span class="material-documents__value">
                            {{
                                !isEditor ?
                                    documentData.date_test && documentData.date_test !== 'null' ? $moment(documentData.date_test).format('DD MMMM YYYY') : '—'
                                    : '—'
                            }}
                        </span>
                    </div>
                </div>
            </div>

            <div
                v-if="status.key === 2"
                class="material-documents__col d-flex align-items-center"
            >
                <router-link
                    type="button"
                    class="material-documents__start v-btn v-btn--gold"
                    :to="{ name: 'UserTesting', query: { type: 'document', id: documentData.id }}"
                >
                    Пройти тестирование
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import session from '../../api/session';

    export default {
        name: 'MaterialDocuments',
        components: {
            Icon: () => import('@/components/icon/Icon')
        },
        props: {
            documentData: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                isStatusShow: false,
                status: {
                    key: this.documentData.study_status || 1,
                    all: [
                        {
                            key: 1,
                            value: 'Не изучен'
                        },

                        {
                            key: 2,
                            value: 'Изучен'
                        },

                        {
                            key: 3,
                            value: 'Тест пройден'
                        },

                        {
                            key: 'new',
                            value: 'Вышла новая редакция'
                        }
                    ],

                    review: [
                        {
                            key: 1,
                            value: 'Не изучен'
                        },

                        {
                            key: 2,
                            value: 'Изучен'
                        }
                    ]
                }
            };
        },
        computed: {
            statusReviewList() {
                return this.status.review.filter(status => status.key !== this.status.key);
            },

            statusReviewValue() {
                return this.status.review.find(status => status.key === this.status.key);
            },

            statusTestingValue() {
                return this.status.all.find(status => {
                    // вышла новая редакция
                    if (this.status.key === 1 && this.documentData.is_new_version) {
                        if (status.key === 'new') {
                            return status;
                        }
                    }

                    if (status.key === this.status.key) {
                        return status;
                    }
                }).value;
            },

            isEditor() {
                return this.current_user.is_editor_rubric_docs || this.current_user.is_editor_document || this.current_user.is_admin;
            },

            ...mapState('default_data', [
                'current_user'
            ])
        },
        methods: {
            onPreview(data) {
                const docData = {
                    file_name: data.name,
                    extension: 'doc',
                    link: data.link
                };

                const DocumentPreviewModal = () =>
                    import('@/components/editor/modals/DocumentPreviewModal');

                this.$modal.show(
                    DocumentPreviewModal,
                    { attachment: docData },
                    {
                        name: 'document-preview',
                        adaptive: true,
                        maxWidth: 980,
                        width: '100%',
                        height: 'auto'
                    }
                );
            },

            onDownload(link) {
                window.open(link, 'Download');
            },

            async onChangeStatus(event) {
                const { target } = event;
                const optionElement = target.closest('.material-documents-status__option');

                if (optionElement) {
                    this.status.key = parseInt(optionElement.dataset.statusId);

                    if (this.status.key === 2) {
                        try {
                            await session.post(`/api/v1/document/${this.documentData.id}/set_studied/`);
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }

                this.isStatusShow = !this.isStatusShow;
            },

            isExternalLink(link) {
                const re = /\b(document_url)\b/g;

                return link.search(re) !== -1;
            }
        }
    };
</script>

<style scoped lang="scss">
    $black-primary: #171717;
    $gray-primary: #e6ebf3;
    $gray-secondary: #a4aebd;
    $gray-tertiary: #f8f8f8;

    .material-documents {
        $pb: &;

        &__item {
            color: $black-primary;
            padding: 16px;
            border-radius: 8px;
            border: 2px solid $gray-primary;
            & + #{$pb}__item {
                margin-top: 20px;
            }
        }

        &__row {
            gap: 15px;
            display: flex;
            justify-content: space-between;

            &--top {
                align-items: center;

                margin: 0 0 24px;
                padding: 0 0 12px;
                border-bottom: 1px solid $gray-primary;
                @media screen and (max-width: 768px) {
                    align-items: flex-end;
                    flex-direction: column;
                }

                #{$pb}__col {
                    &:last-of-type {
                        @media screen and (max-width: 768px) {
                            order: -1;
                        }
                    }
                }
            }

            &--bottom {
                @media screen and (max-width: 1320px) {
                    flex-direction: column;
                }
            }

            &--table {
                gap: 50px;
                @media screen and (max-width: 1440px) {
                    gap: 25px;
                }

                @media screen and (max-width: 768px) {
                    flex-wrap: wrap;
                }

                #{$pb}__col {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    @media screen and (max-width: 768px) {
                        flex-basis: calc((100% / 2) - 50px);
                    }

                    @media screen and (max-width: 480px) {
                        flex-basis: 100%;
                    }
                }
            }
        }

        &__name {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
        }

        &__actions {
            gap: 10px;
            display: flex;
            align-items: center;
        }

        &__action {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 100px;
            background-color: transparent;
            border: 2px solid $gray-primary;
        }

        &__label {
            color: $gray-secondary;
            font-size: 14px;
            font-weight: 400;
            display: block;
            margin-bottom: 6px;
        }

        &__value {
            font-weight: 600;
        }

        &-status {
            font-size: 14px;
            user-select: none;

            cursor: pointer;
            width: 160px;
            position: relative;
            display: inline-block;
            border-radius: 100px;
            padding: 7px 16px 7px 12px;
            background-color: $gray-tertiary;
            @media screen and (max-width: 768px) {
                width: 100%;
            }

            &__header {
                gap: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            &__value {
                gap: 10px;
                display: flex;
                align-items: center;
            }

            &__arrow {
                transition: transform .2s ease;

                #{$pb}-status--show & {
                    transform: rotate(180deg);
                }
            }

            &__dropdown {
                opacity: 0;
                visibility: hidden;

                top: 35px;
                left: 0;
                right: 0;
                position: absolute;
                border-radius: 0 0 16px 16px;
                background-color: $gray-tertiary;
                #{$pb}-status--show & {
                    opacity: 1;
                    visibility: visible;
                }
            }

            &__option {
                gap: 10px;
                display: flex;
                align-items: center;
                padding: 7px 16px 7px 12px;
                border-top: 1px solid $gray-primary;
            }

            &--show {
                border-radius: 16px 16px 0 0;
            }
        }

        &__start {
            color: $black-primary;
            height: 40px;
            max-height: 40px;
            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
    }
</style>
